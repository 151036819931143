import React, { Component } from 'react'
import Card from 'react-bootstrap/Card'
import './Location.css'
// import location from './images/location.png'

export default class Weather extends Component{
    render(){
        return(
            <div>
                <Card className="locationCard">
                    <Card.Header style={{"fontSize": "1.2rem"}}>Address</Card.Header>
                    <Card.Body>
                        <Card.Title >
                            <iframe 
                                className="iframe"
                                title="This is a unique title" 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3788.1807537397917!2d73.62683191489663!3d18.293340280858548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc29bee0767d639%3A0x3040edeab35670a1!2sTorna%20Campsites!5e0!3m2!1sen!2sus!4v1597859626426!5m2!1sen!2sus"  
                                frameBorder="true"
                            ></iframe>
                        </Card.Title>
                        <Card.Text>
                            Torna Fort Road, Velhe, Pune<br/>
                                <br/>
                                For bookings:<a href="tel:+(91)7822949347"> +(91)782-294-9347</a>
                                <br/>
                                Office:<a href="tel:+(91)7798884419"> +(91)779-888-4419</a>                                                    
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}