import React from "react";
import './Footer.css';

export default class Footer extends React.Component{
    render(){
        return (
            <div className="FooterStyle"> 
                <p>
                    &copy; {new Date().getFullYear()} Torna Campsites | All rights reserved 
                </p>
            </div>
        );
    }
}