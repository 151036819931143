import React, { Component } from 'react'

// import torna from './images/torna.jpg'
import Weather from '../Weather/Weather.js'
import Location from '../Location/Location.js'
import './Home.css'
import QuoteList from '../PriceQuote/QuoteList'
import ImageCarousel from '../ImageCarousel/ImageCarousel.js'
import Welcome from './Welcome.js'

export default class Home extends Component{
    render(){
        return(
            <div className="container">
                <Welcome/>
                <div className="imageAndWeatherCardContainer">
                    <div className="imageCarouselContainer">
                        <ImageCarousel/>
                    </div>
                    <Weather/> 
                </div>
                <QuoteList/>
                <Location/>
            </div>
        );
    }
}