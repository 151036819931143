import React, { Component } from 'react'
import './AboutUs.css'
import Location from '../Location/Location.js'

export default class AboutUs extends Component{
    render(){
        return(
            <div className="aboutusContainer container ">
                <h4>About Us</h4>
                <p> Mission to provide the real camping experience. </p>             
                <Location/>
            </div>
        );
    }
}